import { gql } from "graphql-request";
import { prepareClient } from "@telia/b2x-graphql-request";

const client = prepareClient("/.api/sitewide-content/graphql");

export const query = <T>(query: string, variables = {}): Promise<T> => {
  return client.request<T>(query, variables);
};

// single-spa exports
export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchMicrocopyGraphQl(locale: string, readableId: string[]): Promise<any> {
  return await query(
    gql`
      query getMicrocopyResourceSet($locale: Locale, $readableId: [String!]!) {
        microcopyResourceSets(
          locale: $locale
          filters: [{ key: READABLE_ID, value: $readableId }]
        ) {
          elements {
            readableId
            resources {
              key
              value
            }
          }
        }
      }
    `,
    { locale, readableId }
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function fetchMicrocopy(locale: string, identifiers: string[]): Promise<any> {
  const microcopy = await fetchMicrocopyGraphQl(locale, identifiers);
  const results = {};
  microcopy.microcopyResourceSets.elements.forEach((element) => {
    element.resources.forEach((resource) => {
      results[`${element.readableId}.${resource.key}`] = resource.value;
    });
  });
  return results;
}

export async function mount(): Promise<void> {
  return Promise.resolve();
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}
